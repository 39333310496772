var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"toolbarContainer"},[(!_vm.viewOnly)?_c('toolbar',{attrs:{"color-list":_vm.colorList,"color":_vm.selectedColor,"tool":_vm.selectedTool,"signer":_vm.selectedSigner,"signerList":_vm.cSignerList,"saveButton":""},on:{"update:color":function($event){_vm.selectedColor=$event},"update:tool":function($event){_vm.selectedTool=$event},"update:signer":function($event){_vm.selectedSigner=$event},"save":_vm.save}}):_vm._e()],1),_c('div',{ref:"stageContainer",attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.deleteShape($event)}}},[_c('v-stage',{ref:"stage",attrs:{"config":{
        width: _vm.width * _vm.scale,
        height: _vm.height * _vm.scale,
        scaleX: _vm.scale,
        scaleY: _vm.scale,
        listening: !_vm.viewOnly,
      }},on:{"mousedown":_vm.onMouseDown,"mousemove":_vm.onMouseMove,"mouseup":_vm.onMouseUp}},[_c('v-layer',{ref:"layer"},[_c('v-rect',{ref:"templateRect",attrs:{"config":_vm.templateRectangle}}),_vm._l((_vm.currPageSigList),function(rect,index){return _c('v-group',{key:("rect_" + index),attrs:{"config":{
            id: ("g" + (rect.id)),
            name: rect.name,
          }},on:{"dragend":_vm.adjustTransformation}},[_c('v-rect',{attrs:{"config":rect},on:{"transformend":_vm.adjustTransformation}}),_c('v-text',{ref:("text" + index),refInFor:true,attrs:{"config":{
              text: rect.signature,
              fontSize: _vm.sigTextList[index],
              x: rect.x,
              y: rect.y,
              height: rect.height * rect.scaleY,
              width: rect.width * rect.scaleX,
              verticalAlign: 'middle',
              align: 'center',
              listening: false,
            }}})],1)}),_vm._l((_vm.currPagePaydayList),function(payday,index){return _c('v-group',{key:("payday_" + index),attrs:{"config":{
            id: ("g" + (payday.id)),
            name: payday.name,
          }},on:{"dragend":_vm.adjustTransformation}},[_c('v-rect',{attrs:{"config":payday},on:{"transformend":_vm.adjustTransformation}}),_c('v-text',{ref:("text" + index),refInFor:true,attrs:{"config":{
              text: payday.signature,
              fontSize: _vm.sigTextList[index],
              x: payday.x,
              y: payday.y,
              height: payday.height * payday.scaleY,
              width: payday.width * payday.scaleX,
              verticalAlign: 'middle',
              align: 'center',
              listening: false,
            }}})],1)}),_vm._l((_vm.currPageCheckboxList),function(box,index){return _c('v-path',{key:("checkbox_" + index),attrs:{"config":box},on:{"transformend":_vm.adjustTransformation,"dragend":_vm.adjustTransformation}})}),_c('v-transformer',{ref:"transformer",attrs:{"config":_vm.transformerConfig}})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }