<template>
  <div>
    <div
      id="viewerContainer"
      class="viewerContainer"
      :style="!viewOnly ? 'padding-top:7rem' : ''"
      ref="viewerContainer"
    >
      <div id="viewer" ref="container" class="pdfViewer"></div>
      <div class="konvaContainer">
        <Canvas
          v-if="pageViewport && ptViewport"
          ref="canvas"
          :scale="pageViewport.scale"
          :width="ptViewport.width"
          :height="ptViewport.height"
          :page="page"
          :color="selectedColor"
          :signerList="signerRoles"
          :metadata="metadata"
          :viewOnly="viewOnly"
          @save="save"
          @update:metadata="$emit('update:metadata', $event)"
          class="konva"
        ></Canvas>
      </div>
    </div>
    <div class="is-flex is-justify-content-flex-end" v-if="!viewOnly">
      <button class="button is-primary" @click="$refs.canvas.save()">
        {{ $t("policies.template.buttons.save") }}
      </button>
    </div>
  </div>
</template>

<script>
"use strict";
// import pdfjsLib from "pdfjs-dist/webpack";
import {
  EventBus,
  PDFLinkService,
  PDFFindController,
  PDFSinglePageViewer,
} from "pdfjs-dist/web/pdf_viewer";
var pdfjsLib = require("pdfjs-dist/build/pdf");
// var pdfjsLib = require("pdfjs-dist/webpack");

if (process.env.VUE_ENV !== "server") {
  if (typeof window !== "undefined" && "Worker" in window) {
    // var PdfjsWorker = require("worker-loader!pdfjs-dist/build/pdf.worker.js");
    // pdfjsLib.GlobalWorkerOptions.workerPort = new PdfjsWorker();
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "../../../pdfjs-dist/build/pdf.worker.js";
  }
}

var CMAP_URL = "../../../node_modules/pdfjs-dist/cmaps/";
var CMAP_PACKED = true;

import Canvas from "./Canvas.vue";
export default {
  components: {
    Canvas,
  },
  props: {
    viewOnly: {
      default: null,
    },
    src: {
      type: ArrayBuffer,
      default: "",
    },
    page: {
      type: Number,
      default: 1,
    },
    rotate: {
      type: Number,
      default: 0,
    },
    scale: {
      type: [Number, String],
      default: 1,
    },
    metadata: {
      default: null,
    },
    signerRoles: Array,
  },
  data() {
    return {
      loading: false,
      pdfViewer: null,
      pdf: null,
      ptViewport: null,
      colorList: ["red", "green"],
      selectedColor: "",
      totalPages: 0,
    };
  },
  watch: {
    src() {
      this.loadPdf();
    },
    page: function (val) {
      this.changePage(val);
    },
    scale: function (val) {
      this.changeScale(val);
    },
    rotate: function (newRotate) {
      if (this.pdfViewer) {
        this.pdfViewer.update(this.scale, newRotate);
        this.pdfViewer.draw();
      }
    },
    totalPages(newVal) {
      this.$emit("totalPages", newVal);
    },
  },
  mounted() {
    this.loadPdf();
  },
  computed: {
    pageViewport() {
      if (this.pdfViewer) {
        const view = this.pdfViewer.getPageView(this.page - 1);
        return view ? view.viewport : null;
      } else {
        return null;
      }
    },
    pageWidth() {
      return this.pdfViewer.getPageView(this.page - 1).width;
    },
    pageHeight() {
      return this.pdfViewer.getPageView(this.page - 1).height;
    },
    pageViewportScale() {
      return this.pdfViewer.getPageView(this.page - 1).viewport.scale || null;
    },
  },
  methods: {
    changePage(pageNum) {
      if (this.pdfViewer) {
        const pageNumInt = parseInt(pageNum);
        this.pdfViewer.scrollPageIntoView({ pageNumber: pageNumInt });
      }
    },
    changeScale(newScale) {
      if (this.pdfViewer) {
        this.pdfViewer.currentScaleValue = newScale;
        // console.log(this.pageViewport.scale);
        // this.$emit("scale", this.pageViewport.scale);
        console.log("changing scale");
      }
    },
    changeColor(color) {
      this.selectedColor = color;
    },
    loadPdf() {
      this.loading = true;
      try {
        const src = this.src;
        // const source = { data: src };
        // if (typeof src === "string")
        //   source.url = src;
        // else if (typeof src === "object" && src !== null)
        //   source.data = src
        // else throw new TypeError("invalid src type");

        var eventBus = new EventBus();
        // (Optionally) enable hyperlinks within PDF files.
        var pdfLinkService = new PDFLinkService({
          eventBus,
        });
        // (Optionally) enable find controller.
        var pdfFindController = new PDFFindController({
          eventBus,
          linkService: pdfLinkService,
        });

        const container = this.$refs["viewerContainer"];

        var pdfSinglePageViewer = new PDFSinglePageViewer({
          scale: 1,
          container,
          eventBus,
          linkService: pdfLinkService,
          findController: pdfFindController,
        });
        pdfLinkService.setViewer(pdfSinglePageViewer);

        this.pdfViewer = pdfSinglePageViewer;

        eventBus.on("pagesinit", () => {
          this.changeScale(this.scale);
          // this.pdfViewer.currentScaleValue = this.scale;
          // this.pdfViewer.currentScaleValue = "page-width";
          // console.log("scale", this.pdfViewer.currentScaleValue);
        });

        // Loading document.
        var loadingTask = pdfjsLib.getDocument({
          data: src,
          cMapUrl: CMAP_URL,
          cMapPacked: CMAP_PACKED,
        });
        loadingTask.promise
          .then((pdfDocument) => {
            this.pdf = pdfDocument;
            this.totalPages = pdfDocument.numPages;
            // Document loaded, specifying document for the viewer and
            // the (optional) linkService.
            pdfSinglePageViewer.setDocument(pdfDocument);

            pdfLinkService.setDocument(pdfDocument, null);

            return pdfDocument.getPage(this.page);
          })
          .then((pdfPage) => {
            this.ptViewport = pdfPage.getViewport({
              scale: 1,
            });
          });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    save(data) {
      this.$emit("save", data);
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/pdfjs-dist/web/pdf_viewer.css";
.viewerContainer {
  display: grid;
  box-sizing: content-box;
  /* padding-top: 3rem;s */
  position: relative;
}
.konvaContainer,
.pdfViewer {
  grid-area: 1/1 !important;
}
.konva {
  margin: 1px auto -8px auto;
  border: 9px solid transparent;
  width: fit-content;
}
</style>
