<template>
  <section class="section has-background-eurotext-light">
    <div>
      <div v-if="showTemplateInfo">
        <ValidationProvider
          :vid="name"
          :name="`${$t('policies.template.disable')}/${$t(
            'policies.template.enable'
          )}`"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field position="is-centered">
            <!-- <b-switch type="is-primary" :left-label="true" v-model="isEnabled">
            <span class="has-text-light">{{
              $t("policies.template.required")
            }}</span>
          </b-switch> -->

            <b-radio-button
              v-model="isEnabled"
              :native-value="false"
              type="is-primary"
              style="min-width: 7rem"
            >
              <span>{{ $t("policies.template.disable") }}</span>
            </b-radio-button>
            <b-radio-button
              v-model="isEnabled"
              :native-value="true"
              type="is-primary"
              style="min-width: 7rem"
            >
              <span>{{ $t("policies.template.enable") }}</span>
            </b-radio-button>
          </b-field>
          <p class="has-text-centered has-text-danger" v-if="errors">
            <span v-for="(error, index) in errors" :key="index">{{
              error
            }}</span>
          </p>
        </ValidationProvider>

        <div class="has-text-centered" v-show="isEnabled">
          <p class="has-text-light">
            {{ $t("policies.template.name.label") }}: {{ name }}
          </p>
        </div>

        <b-field position="is-centered" grouped v-show="isEnabled">
          <slot name="beforeName"> </slot>

          <!-- <b-field>
            <template #label
              ><span class="has-text-light">{{
                $t("policies.template.name.label")
              }}</span></template
            >
            <p class="control">
              <span class="button is-static">{{ name }}</span>
            </p>
          </b-field> -->

          <slot name="afterName"></slot>
        </b-field>

        <div v-show="isEnabled">
          <!-- <div class="has-text-centered has-text-light">
            <span class="is-size-5">{{
              $t("policies.template.file.label")
            }}</span>
          </div> -->
          <div class="columns is-vcentered" style="min-height: 7rem">
            <template v-if="hasImportButton">
              <div class="column has-text-right">
                <slot name="importButton"></slot>
              </div>
              <span class="has-text-light">{{
                $t("policies.template.or")
              }}</span>
            </template>

            <div class="column">
              <b-field class="file is-primary">
                <!-- <b-upload v-model="file" class="file-label" accept=".pdf">
                  <span class="file-cta">
                    <b-icon icon="upload"></b-icon>
                    <span class="file-label">{{
                      $t("policies.template.file.load_button")
                    }}</span>
                  </span>
                </b-upload> -->
                <b-upload
                  :value="file"
                  @input="onTemplateExNovo"
                  accept=".pdf"
                  expanded
                >
                  <a
                    class="button is-primary templ-button-equal-w"
                    ref="upload"
                  >
                    <b-icon icon="upload"></b-icon>
                    <span>{{ $t("policies.template.file.load_button") }}</span>
                  </a>
                </b-upload>
              </b-field>
            </div>
          </div>
        </div>
      </div>

      <div v-if="fileData" v-show="isEnabled">
        <div class="level" style="position: sticky; top: 0">
          <div class="level-left">
            <div class="level-item">
              <div class="field has-addons">
                <p class="control">
                  <button
                    class="button"
                    @click="pageNumber > 1 ? pageNumber-- : 1"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-align-left"></i>
                    </span>
                    <span>{{ $t("policies.template.buttons.page") }} -</span>
                  </button>
                </p>
                <p class="control">
                  <button
                    class="button"
                    @click="pageNumber < totalPages ? pageNumber++ : 1"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-align-center"></i>
                    </span>
                    <span>{{ $t("policies.template.buttons.page") }} +</span>
                  </button>
                </p>
                <p class="control">
                  <button class="button is-static">
                    <span
                      >{{ pageNumber }} /
                      {{ totalPages ? totalPages : "∞" }}</span
                    >
                  </button>
                </p>
              </div>
            </div>

            <div class="level-item">
              <div class="field has-addons">
                <p class="control">
                  <button
                    class="button"
                    @click="scale -= scale > 0.2 ? 0.1 : 0"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-align-left"></i>
                    </span>
                    <span>Zoom -</span>
                  </button>
                </p>
                <p class="control">
                  <button class="button is-static">
                    <span>{{ formattedZoom }} %</span>
                  </button>
                </p>
                <p class="control">
                  <button class="button" @click="scale += scale < 2 ? 0.1 : 0">
                    <span class="icon is-small">
                      <i class="fas fa-align-center"></i>
                    </span>
                    <span>Zoom +</span>
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div class="level-right">
            <div class="buttons">
              <b-upload
                v-model="file"
                accept=".pdf"
                class="button"
                v-if="!viewOnly"
              >
                <b-icon icon="upload"></b-icon>
                <span>{{ $t("policies.template.buttons.change_pdf") }}</span>
              </b-upload>
              <!-- <b-button :enabled="file" native-type="button" icon-left="">
                {{ $t("policies.template.buttons.change_pdf") }}
              </b-button> -->
              <button
                :enabled="file"
                type="button"
                class="button"
                @click="downloadFile"
              >
                <b-icon icon="download"></b-icon>
                <span>{{ $t("policies.template.buttons.download_pdf") }}</span>
              </button>
            </div>
          </div>
        </div>

        <section class="box has-background-light">
          <PDFViewer
            v-if="fileData"
            :src="fileData"
            :scale="parseFloat(scale)"
            :page="pageNumber"
            :metadata="metadata"
            :signerRoles="signerRoles"
            :viewOnly="viewOnly"
            @totalPages="updateTotalPages"
            @save="save"
            @update:metadata="$emit('metadata', $event)"
          ></PDFViewer>
        </section>

        <div class="">
          <div class="field has-addons">
            <p class="control">
              <button class="button" @click="pageNumber > 1 ? pageNumber-- : 1">
                <span class="icon is-small">
                  <i class="fas fa-align-left"></i>
                </span>
                <span>{{ $t("policies.template.buttons.page") }} -</span>
              </button>
            </p>
            <p class="control">
              <button
                class="button"
                @click="pageNumber < totalPages ? pageNumber++ : 1"
              >
                <span class="icon is-small">
                  <i class="fas fa-align-center"></i>
                </span>
                <span>{{ $t("policies.template.buttons.page") }} +</span>
              </button>
            </p>
            <p class="control">
              <button class="button is-static">
                <span
                  >{{ pageNumber }} / {{ totalPages ? totalPages : "∞" }}</span
                >
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import PDFViewer from "@/components/pdfViewer/PDFViewer";
import "pdfjs-dist/build/pdf.worker.entry";
import FileSaver from "file-saver";

export default {
  components: {
    PDFViewer,
    ValidationProvider,
  },
  props: {
    template: {
      type: [String, Object],
      default: null,
    },
    folderName: {
      type: String,
      default: "",
    },
    groupType: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: "",
    },
    signerRoles: Array,
    showTemplateInfo: {
      default: true,
    },
    viewOnly: { default: false },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mTemplate: this.template,
      fileData: null,
      fileName: "",
      file: null,
      scale: 1,
      pageNumber: 1,
      totalPages: 10,
      metadata: null,
      isEnabled: this.enabled,
      saved: false,
    };
  },
  watch: {
    file: {
      handler(newVal) {
        this.onFilePicked(newVal);
      },
    },
    template: {
      deep: true,
      handler(newValue) {
        // if (newValue) {
        this.mTemplate = newValue;
        this.loadTemplate();
        // }
      },
    },
    enabled(newVal) {
      this.isEnabled = newVal;
    },
    isEnabled(newVal) {
      console.log(newVal);
      if (!newVal) {
        this.file = null;
        this.saved = false;
        this.$emit("deleteTemplate");
      } else {
        this.loadTemplate();
      }
    },
  },
  computed: {
    formattedZoom() {
      return Number.parseInt(this.scale * 100);
    },
    hasImportButton() {
      return this.$slots.importButton;
    },
    isSavedOrDisabled() {
      return !this.isEnabled || this.saved;
    },
  },
  methods: {
    downloadFile() {
      if (this.file) {
        FileSaver.saveAs(this.file);
      }
    },
    loadTemplate() {
      if (this.template) {
        if (this.template.file) {
          this.file = this.template.file;
          // this.loadFile(this.template.file);
        } else {
          this.fileName = "";
          this.fileData = null;
        }
        if (this.template.metadata) {
          this.metadata = this.template.metadata;
        }
      }
    },
    loadFile(file) {
      if (file) {
        // const files = event.target.files;
        this.fileName = file.name;
        const fileReader = new FileReader();
        fileReader.onload = () => {
          this.fileData = fileReader.result;
        };
        // fileReader.addEventListener("load", () => {
        //   this.imageUrl = fileReader.result;
        // });
        // fileReader.readAsDataURL(files[0]);
        fileReader.readAsArrayBuffer(file);
      } else {
        this.fileName = "";
        this.fileData = null;
      }
    },
    onFilePicked(file) {
      this.loadFile(file);

      this.$emit("input", file);
    },
    onTemplateExNovo(file) {
      this.file = file;
      this.metadata = null;

      this.$emit("metadata", this.metadata);
    },
    save(data) {
      this.saved = true;
      this.$emit("save", data);
    },
    updateTotalPages(total) {
      this.totalPages = total;
    },
  },
  created() {
    this.loadTemplate();
  },
};
</script>

<style scoped>
.controlContainer {
  display: flex;
  align-items: center;
}
.templ-button-equal-w {
  min-width: 16rem;
}
</style>
