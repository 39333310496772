<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <!-- <div class="columns">
        <div class="column">
          <div
            v-for="(signature, index) in mSignatures"
            class="level"
            :key="`signature_${index + 1}`"
          >
            <div class="level-item item-left">
              <span>{{ signature.fullName }}</span>
            </div>
            <div class="level-item item-right">
              <span>{{ signature.timeUTC }}</span>
            </div>
          </div>
          <div class="level">
            <div class="level-item item-left">
              <span>{{ $t("timestamp") }}</span>
            </div>
            <div class="level-item item-right">
              <span>{{ timestamp }}</span>
            </div>
          </div>
        </div>
      </div> -->

      <div class="columns is-centered">
        <div class="column is-narrow">
          <table class="table" v-if="signatures.length > 0 || timestamp">
            <thead>
              <tr>
                <th>
                  {{ $t("document.validation.signature.full_name") }}
                </th>
                <th>{{ $t("document.validation.signature.time_utc") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(signature, index) in mSignatures"
                :key="`signature_${index + 1}`"
              >
                <td>
                  {{ signature.fullName }}
                </td>
                <td>
                  {{ signature.timeUTC }}
                </td>
              </tr>
              <tr v-if="timestamp">
                <td>
                  {{ $t("timestamp") }}
                </td>
                <td>
                  {{ timestamp }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            {{ $t("document.validation.signature.time_utc") }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    signatures: {
      type: Array,
      required: false,
      default: () => [],
    },
    timestamp: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    mSignatures() {
      return this.signatures.map((sig) => {
        let fullName = `${sig.name} ${sig.surname}`;
        return {
          ...sig,
          fullName,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-left {
  justify-content: flex-end;
  margin-right: 1rem;
}
.item-right {
  justify-content: flex-start;
  margin-left: 1rem;
}
</style>
