<template>
  <div>
    <b-loading :active="uploading" :is-full-page="false"></b-loading>

    <div class="tile is-ancestor is-flex-wrap-wrap">
      <div
        class="tile is-parent"
        v-for="(fileType, key) in files"
        :key="key"
        v-show="!fileType.hidden"
      >
        <div class="tile is-child box">
          <b-upload-with-validation
            v-model="fileType.files"
            :rules="{ required: !!fileType.required }"
            multiple
          >
            <template #label>
              <span class="">{{
                $t(`policies.rao.${key}.label`).concat(
                  fileType.required ? "*" : ""
                )
              }}</span>
            </template>
            <template #default>
              <a class="button is-primary is-fullwidth">
                <b-icon icon="text-box-plus"></b-icon>
                <span>{{ $t("policies.upload.buttons.select_file") }}</span>
              </a>
            </template>
          </b-upload-with-validation>

          <b-field
            grouped
            group-multiline
            v-show="fileType.files && fileType.files.length > 0"
          >
            <div
              class="control"
              v-for="(file, index) in fileType.files"
              :key="index"
            >
              <b-tag
                type="is-primary-light"
                close-type="is-primary"
                attached
                closable
                aria-close-label="Close tag"
                @close="removeFile(key, index)"
              >
                {{ file.name }}
              </b-tag>
            </div>
          </b-field>
        </div>
      </div>
    </div>

    <b-button
      v-show="uploadButton"
      @click="onUploadClick"
      type="is-primary"
      icon-left="upload"
    >
      {{ $t("buttons.upload") }}
    </b-button>
  </div>
</template>

<script>
import { inputService } from "../../../services";
import { createMetadata, getBase64FromFile } from "../../../helpers";
import BUploadWithValidation from "../../inputs/BUploadWithValidation.vue";

function defaultFiles() {
  return {
    identificationFile: { files: null, hidden: false, required: true },
    fiscalCodeFile: { files: null, hidden: false, required: false },
    imageFile: { files: null, hidden: false, required: false },
    oraganizationFile: { files: null, hidden: false, required: false },
    otherFiles: { files: null, hidden: false, required: false },
  };
}
export default {
  components: { BUploadWithValidation },
  props: {
    fileTypes: null,
    companyName: {
      type: String,
      required: true,
    },
    uploadButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    idDocumentType: {
      type: String,
      required: false,
      default: "",
    },
    infos: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: { value: "" },
          surname: { value: "" },
          fiscalCode: { value: "" },
          documentType: { value: "" },
          domainUsername: { value: "" },
        };
      },
    },
  },
  data() {
    return {
      files: undefined,
      uploading: false,
    };
  },
  watch: {
    files(newVal) {
      this.$emit("update:fileTypes", newVal);
    },
    fileTypes: {
      handler() {
        this.parseFileTypes();
      },
      deep: true,
    },
  },
  methods: {
    removeFile(fileType, index) {
      this.files[fileType].files.splice(index, 1);
    },
    async onUploadClick() {
      await this.uploadFiles();
      this.$emit("upload-complete");
    },
    async uploadFiles() {
      try {
        this.uploading = true;
        for (let key in this.files) {
          const files = this.files[key].files;
          if (files) {
            for (let file of files) {
              const b64 = await getBase64FromFile(file);
              // const sha256 = await hexSHA256FromFile(file);
              const mime = file.type;
              const fileRes = await inputService.uploadFile(
                this.companyName,
                b64,
                mime,
                null //sha256
              );
              let docTypeStr = this.$t(`policies.rao.${key}.label`);
              if (key === "identificationFile") {
                docTypeStr = this.$t(
                  `policies.rao.documentType.${this.infos.documentType.value}`
                  // `policies.rao.documentType.${this.idDocumentType}`
                );
              }
              const properties = [
                {
                  name: "progressive",
                  value: 0,
                },
                {
                  name: "codice_fiscale",
                  value: this.infos.fiscalCode.value,
                },
                {
                  name: "cognome",
                  value: this.infos.surname.value,
                },
                {
                  name: "nome",
                  value: this.infos.name.value,
                },
                {
                  name: "sign_customer",
                  value: this.infos.domainUsername.value,
                },
                {
                  name: "username",
                  value: this.$store.state.users.subNickname,
                },
                {
                  name: "description",
                  value: docTypeStr,
                },
                {
                  name: "source_filename",
                  value: file.name,
                },
              ];
              // const className = this.$config.insurance.user_document_class;
              const className = `${this.$store.getters["users/getRASubRA"]}:${this.$config.insurance.user_document_class}`;
              const metadata = createMetadata(
                properties,
                className,
                file.type,
                fileRes["original_path"],
                fileRes["document_id"]
              );
              console.log(metadata);
              const response = await inputService.uploadMetadata(
                this.companyName,
                className,
                metadata,
                0,
                0
              );

              if (!response.error) {
                // Notify user
                this.$buefy.toast.open({
                  message: this.$t("policies.upload.success.file_loaded", {
                    file: file.name,
                  }),
                  type: "is-success",
                  duration: 3000,
                  queue: false,
                });
              } else {
                const text = this.$t(
                  "policies.upload.error.while_loading_file",
                  { file: file.name }
                );
                // Notify user
                this.$buefy.toast.open({
                  message: text,
                  type: "is-danger",
                  duration: 4000,
                  queue: false,
                });
              }
            }
          }
        }
        return true;
      } catch (e) {
        console.log(e);
        return false;
      } finally {
        this.uploading = false;
      }
    },
    parseFileTypes() {
      const files = {};
      Object.keys(this.fileTypes).forEach((f) => {
        const file = this.fileTypes[f];
        const obj = { ...file };
        if (!file.files) obj.files = null;
        files[f] = obj;
      });
      this.files = files;
    },
  },
  created() {
    if (!this.fileTypes) {
      this.files = defaultFiles();
    } else {
      this.parseFileTypes();
    }
  },
};
</script>

<style></style>
