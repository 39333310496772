<template>
  <section class="section">
    <div class="content">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="buttons">
              <button
                v-for="(button, index) in einvoice.button"
                :key="`button_${index}`"
                class="button is-primary"
                @click="onActionButton(button)"
              >
                <b-icon
                  :icon="$config.icons.buttons[`${button.label}`]"
                ></b-icon>
                <span>{{ $t(`einvoice.buttons.${button.label}`) }}</span>
              </button>
              <button
                v-if="isCreditEinvoice"
                type="button"
                class="button is-primary"
                @click="createSdIEInvoice"
              >
                <b-icon :icon="$config.icons.buttons.new_sdi_einvoice"></b-icon>
                <span>{{ $t("buttons.new_sdi_einvoice") }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button
              type="button"
              class="button is-primary"
              @click="displayDataFilterDialog = true"
            >
              <b-icon icon="glasses"></b-icon>
              <span>{{
                isMobile
                  ? $t("document.detail.mobile.buttons.filter_data")
                  : $t("document.detail.buttons.filter_data")
              }}</span>
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="!status.isLoadingDetails"
        class="box has-background-eurotext-light has-text-white-bis"
      >
        <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
        <b-field
          v-for="(prop, index) in displayedProperties"
          :key="index"
          v-show="prop.visible"
          :label="prop.description"
          custom-class="has-text-white-bis"
          horizontal
        >
          <span v-if="prop.name === '_einv_color'">
            <b-icon
              icon="circle"
              size="is-medium"
              :type="
                einvoice['status'] ? `is-itside-${einvoice['status']}` : ''
              "
            ></b-icon>
          </span>
          <b-input
            v-else
            :value="String(einvoice[prop.name])"
            custom-class="cursor-default"
            disabled
            expanded
          ></b-input>
        </b-field>
      </div>
    </div>

    <b-modal
      :active.sync="displayDownloadDialog"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <modal-type-selection
          :downloadTypes="downloadTypes"
          @download="download"
        ></modal-type-selection>
      </template>
    </b-modal>

    <b-modal
      :active.sync="displayDataFilterDialog"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <TableColumnFilter
          :columns="displayedDataFilterColumn"
          :data="displayedProperties"
          @order-change="onOrderChange"
          @toggleVisibility="ontoggleVisibility"
          @toggleVisibilityAll="ontoggleVisibilityAll"
        >
          <template #header>
            <p class="modal-card-title is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.header")
                  : $t("table.col-options.header")
              }}
            </p>
          </template>
          <template #beforeTable>
            <p class="is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.col-filter-title")
                  : $t("table.col-options.col-filter-title")
              }}
            </p>
          </template>
        </TableColumnFilter>
      </template>
    </b-modal>

    <b-modal
      v-if="errorsNS"
      :active.sync="displayErrorNSDialog"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <ErrorNSDisplay :errors="errorsNS"></ErrorNSDisplay>
      </template>
    </b-modal>
  </section>
</template>

<script>
import ModalTypeSelection from "@/components/ModalTypeSelection";
import TableColumnFilter from "@/components/DocumentTable/TableColumnFilter";
import ErrorNSDisplay from "@/components/ErrorNSDisplay";
import { getEInvoiceDownloadType } from "@/helpers/constants";
import { classService, downloadService, inputService } from "@/services";
import { uaMixin } from "@/mixins/user-agent";
export default {
  components: {
    ModalTypeSelection,
    TableColumnFilter,
    ErrorNSDisplay,
  },
  mixins: [uaMixin],
  props: {
    companySchema: {
      type: String,
      required: true,
    },
    className: {
      required: false,
      default: "",
    },
    properties: {
      type: Array,
      required: true,
    },
    einvoice: {
      type: Object,
      required: true,
    },
    isCredit: {
      type: Boolean,
    },
    stateKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      status: {
        isLoadingDetails: false,
        isDownloading: false,
      },
      displayDownloadDialog: false,
      displayDataFilterDialog: false,
      displayErrorNSDialog: false,
      displayedDataFilterColumn: [],
      orderedPropertiesNames: null,
      visiblePropertiesNames: null,
      downloadTypes: [],
      errorsNS: null,
    };
  },
  computed: {
    /**
     * Return true if the document class is a credit (active) one.
     * If no classId is provided (classId < 0), returns false.
     */
    isCreditEinvoice: function () {
      return this.isCredit;
    },
    isLoading: function () {
      for (const key of Object.keys(this.status)) {
        if (this.status[key]) {
          return true;
        }
      }
      return false;
    },
    mProps: function () {
      return this.properties.map((prop) => {
        return {
          name: prop.field,
          description: prop.label,
          visible: true,
        };
      });
    },
    mStateKey: function () {
      if (this.stateKey) {
        return `id.${this.stateKey}`;
      } else {
        return `id.${this.stateKey}.${this.companySchema}`;
      }
    },
    displayedProperties: function () {
      const orderedProps = [];
      // Add the properties in the saved order
      if (this.orderedPropertiesNames) {
        for (let i = 0; i < this.orderedPropertiesNames.length; i++) {
          let op = this.orderedPropertiesNames[i];
          let foundProp = this.mProps.find((prop) => prop.name === op);
          if (foundProp) orderedProps.push({ ...foundProp });
        }
      }
      const allOrdered = [...orderedProps];
      // Add all the properties that are not stored in the saved order
      for (let i = 0; i < this.mProps.length; i++) {
        if (
          orderedProps.findIndex((prop) => prop.name === this.mProps[i].name) <
          0
        ) {
          allOrdered.push({ ...this.mProps[i] });
        }
      }
      // Toggle visibility acording to the saved list of visible properties
      if (this.visiblePropertiesNames) {
        allOrdered.forEach((obj) => {
          const visible = this.visiblePropertiesNames.indexOf(obj.name) !== -1;
          obj.visible = visible;
        });
      }
      return allOrdered;
    },
  },
  methods: {
    closeDownloadDialog() {
      this.displayDownloadDialog = false;
    },
    createSdIEInvoice() {
      inputService
        .createSdIEInvoice(
          this.companySchema,
          this.className,
          this.einvoice["etDocumentId"]
        )
        .then();
    },
    download(payload) {
      this.closeDownloadDialog();
      this.status.isDownloading = true;
      var artifacts = 0;
      for (const type of payload) {
        artifacts += type;
      }
      const ids = [this.einvoice.invoiceId];
      downloadService
        .downloadEInvoices(
          this.companySchema,
          this.$i18n.locale,
          "ddMMyyyy",
          true,
          ids,
          artifacts,
          this.isCredit
        )
        .then((data) => {
          console.log(data);
        })
        .finally(() => (this.status.isDownloading = false));
    },
    onActionButton(button) {
      if (button.action === "SET_SOLVED") {
        this.$buefy.dialog.prompt({
          message: this.$t("einvoice.dialog.add_message"),
          inputAttrs: {
            placeholder: this.$t("einvoice.dialog.add_message_placeholder"),
          },
          trapFocus: true,
          onConfirm: (value) => this.doEinvoiceAction(button, value),
        });
      } else {
        this.doEinvoiceAction(button);
      }
    },
    onOrderChange(newData) {
      this.orderedPropertiesNames = newData.map((data) => data.name);
      this.storeState();
    },
    ontoggleVisibility(visibleObjects) {
      this.visiblePropertiesNames = visibleObjects.map((obj) => obj.name);
      this.storeState();
    },
    ontoggleVisibilityAll(visibleObjects) {
      this.visiblePropertiesNames = visibleObjects.map((obj) => obj.name);
      this.storeState();
    },
    storeState() {
      let state = {};
      state.order = this.orderedPropertiesNames;
      state.visible = this.visiblePropertiesNames;
      if (Object.keys(state).length) {
        localStorage.setItem(this.mStateKey, JSON.stringify(state));
      }
    },
    doEinvoiceAction(button, additionalVal) {
      this.status.isDownloading = true;
      const payload = {
        docId: this.einvoice.etDocumentId,
        action: button.action,
      };
      if (additionalVal) {
        payload.solDescr = additionalVal;
      }
      classService
        .doEInvoiceAction(this.companySchema, payload)
        .then((data) => {
          if (data) {
            if (data.errors) {
              this.errorsNS = data.errors;
              this.displayErrorNSDialog = true;
            }
            if (button.action === "SHOW" || button.action === "EXPORT_AS_PDF") {
              if (data.url) {
                let url = data.url;
                const fileName = data["fileName"];
                const tab = {
                  id: `${fileName}`,
                  component: "IFrame",
                  label: `${fileName}`,
                  icon: "",
                  props: {
                    url: url,
                    mimeType: data["mimeType"],
                  },
                };
                this.$store.dispatch("tabs/openTab", tab);
              } else {
                console.error("No url returned to open in iframe");
              }
            }
          }
        })
        .finally(() => (this.status.isDownloading = false));
    },
  },
  beforeMount() {
    const stateString = localStorage.getItem(this.mStateKey);
    if (stateString) {
      const restoredState = JSON.parse(stateString);
      this.orderedPropertiesNames = restoredState.order;
      this.visiblePropertiesNames = restoredState.visible;
    }
  },
  mounted() {
    this.downloadTypes = getEInvoiceDownloadType();
    this.displayedDataFilterColumn = [
      {
        field: "description",
        label: this.$t("document.detail.filter_column_label"),
      },
    ];
  },
};
</script>

<style></style>
